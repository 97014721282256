import React from 'react';

import { Navigation } from '../Navigation';
import { Footer } from '../Footer';

interface Props {
    children: React.ReactNode
}

export const Page = ({ children }: Props) => {

  return (
    <>
      <Navigation />
      {children}
      <Footer />
    </>
  );
};
