// extracted by mini-css-extract-plugin
export var CloseIcon = "Navigation-module--CloseIcon--68a77";
export var HamburgerIcon = "Navigation-module--HamburgerIcon--9dec0";
export var MobileNavigation = "Navigation-module--MobileNavigation--6f81b";
export var MobileNavigationPanel = "Navigation-module--MobileNavigationPanel--0b9be";
export var Navigation = "Navigation-module--Navigation--1ed0a";
export var NavigationButton = "Navigation-module--NavigationButton--6ffa2";
export var NavigationItem = "Navigation-module--NavigationItem--59658";
export var NavigationItems = "Navigation-module--NavigationItems--7c2fb";
export var NavigationLogo = "Navigation-module--NavigationLogo--88c33";
export var NavigationMobileList = "Navigation-module--NavigationMobileList--8a63e";
export var NavigationWrapper = "Navigation-module--NavigationWrapper--5f32c";