import React, { useState } from 'react';
import { Link } from 'gatsby';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

import * as styles from './Navigation.module.scss';
import LogoImg from '../../assets/svg/logoSmall.svg';
import { Layout } from '../Layout';

// type Props = {
//   onSetLanguage?: (language: string) => void;
// };

const NavigationList = ({
  className,
  showMainPage,
  onClick,
}: {
  className: string;
  showMainPage?: boolean;
  onClick?: () => void;
}) => {
  return (
    <ul className={className}>
      {showMainPage && (
        <li className={styles.NavigationItem}>
          <Link to='/' onClick={onClick}>
            Strona główna
          </Link>
        </li>
      )}
      <li className={styles.NavigationItem}>
        <Link to='/kursy' onClick={onClick}>
          Kursy
        </Link>
      </li>
      <li className={styles.NavigationItem}>
        <Link to='/oferta' onClick={onClick}>
          Oferta
        </Link>
      </li>
      <li className={styles.NavigationItem}>
        <Link to='/nauczyciele' onClick={onClick}>
          Nauczyciele
        </Link>
      </li>
      <li className={styles.NavigationItem}>
        <Link to='/kontakt' onClick={onClick}>
          Kontakt
        </Link>
      </li>
    </ul>
  );
};

export const Navigation = () => {
  const [isOpened, handleOpen] = useState(false);

  return (
    <nav className={styles.Navigation}>
      <Layout className={styles.NavigationWrapper}>
        <Link to='/'>
          <LogoImg className={styles.NavigationLogo} />
        </Link>
        <NavigationList className={styles.NavigationItems} />
        {/* <div> */}
        {/*  <button */}
        {/*    className={styles.NavigationButton} */}
        {/*    type='button' */}
        {/*    onClick={(): void => props.onSetLanguage('pl')} */}
        {/*  > */}
        {/*    <span role='img' aria-label='flag-pl'> */}
        {/*      🇵🇱 */}
        {/*    </span> */}
        {/*  </button> */}
        {/*  <button */}
        {/*    className={styles.NavigationButton} */}
        {/*    type='button' */}
        {/*    onClick={(): void => props.onSetLanguage('en')} */}
        {/*  > */}
        {/*    <span role='img' aria-label='flag-en'> */}
        {/*      🇬🇧 */}
        {/*    </span> */}
        {/*  </button> */}
        {/* </div> */}

        <div className={styles.MobileNavigation}>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={() => handleOpen(true)}
            edge='start'
            className={styles.HamburgerIcon}
          >
            <MenuIcon />
          </IconButton>

          <Drawer
            anchor='right'
            className={styles.RightNavigation}
            onClose={() => handleOpen(false)}
            open={isOpened}
            classes={{
              paper: styles.MobileNavigationPanel,
            }}
          >
            <IconButton
              color='inherit'
              aria-label='open drawer'
              onClick={() => handleOpen(false)}
              edge='start'
              className={styles.CloseIcon}
            >
              <CloseIcon />
            </IconButton>

            <NavigationList
              className={styles.NavigationMobileList}
              showMainPage
              onClick={() => handleOpen(false)}
            />
          </Drawer>
        </div>
      </Layout>
    </nav>
  );
};
