import React from 'react';
import classnames from 'classnames';

import * as styles from './Layout.module.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
};

export const Layout: React.FC<Props> = ({ children, className }: Props) => {
  return <div className={classnames(styles.Layout, className)}>{children}</div>;
};
