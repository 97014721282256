import React from 'react';
import { GatsbyBrowser } from 'gatsby';
import { Page } from "../components/Page";
import { SEO } from '../components/SEO';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props: { location },
}) => (
  <Page>
    <SEO location={location} />
    {element}
  </Page>
);
